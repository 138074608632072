.okno {
  
    grid-column-start: 2;
    grid-row-start: 2;
    width: 550px;
    margin: auto auto;
    background: #ffffff;
    color: black;
    text-align: center;
    box-shadow: 10px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
  }

  .formularzLogowania{
    display: grid;
  }

  .formularzLabel{
    display: grid;
    font-weight: bold;
    
    margin-bottom: 4px;
  }
  .formularzInput
  {
    width: 400px;
    margin:auto;
    margin-bottom: 17px;
  }

  .formularzButton{
    margin:auto;
    margin-right: 10px;
    min-width: 150px;
    margin-top: 20px;
  }

  