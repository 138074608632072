

.Szarosc {
  background-color: #f3f3f3;
}

/*.NaglowekDiv {
    border: solid;
  display: flex;
  align-items: center;
  padding-left: 5px;
  font-size: 1.5rem;
  font-weight: 500;
  color: white;
}

.WierszElementDiv {
  display: flex;
  border: solid;
  align-items: center;
  padding-left: 5px;
  font-size: 1.2rem;
}
*/