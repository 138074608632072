* {
    box-sizing: border-box;
  }
  .wybor{
    padding: 3px;

  }
.StartPulpit{
    
    width: 100vw;
    height: 100vh;
    /*background-color: rgba(25, 127, 30, 0.186);*/
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
    
    }

    .PasekGornyPulpit{
        border: solid;
        /*border-color: rgb(225, 26, 26);*/
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
        /*background-color: rgb(81, 167, 31);*/
        grid-row-start: 1;
        grid-column-start: 1;
        grid-column-end: 6 ;
        
        border: 4px;
    }

    .PasekWyboruPionPulpit{
        border: solid;
        border-color: rgb(62, 62, 64);
        background-color: rgb(242, 231, 235);
        grid-row-start: 3;
        grid-row-end: 11;
        grid-column-start: 1;
        grid-column-end: 2;
        margin-left: 5%;
        height: 60%;
        padding: 3px;
    }

    .KartaDanychPulpit{
        
        /*background-color: rgba(72, 157, 150, 0.485);*/
        grid-row-start: 2;
        grid-row-end: 11;
        grid-column-start: 2;
        grid-column-end: 10;
        padding: 6px;
        /*margin-left: 3%;
        margin-top: 2%;*/
        /*height: 90%;*/
    }
    .PasekPrzyciskowPulpit{
        /*margin: auto;
        margin-top: 1%;
        margin-left: 1%;*/
        /*background: solid;
        background-color: rgba(161, 167, 117, 0.755);*/
        padding: 5px;
    }

    .PrzyciskPulpit{
        font-size: large;
        
        padding: 4px;
    }