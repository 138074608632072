.KlawiszLogowanie {
    border: solid;
    background-color: #e1e7ed;
    border: none;
    /*padding-right: 15px;*/
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    /*margin: 4px 2px;*/
    cursor: pointer;
    transition-duration: 0.2s;
    border-radius: 5px;
    /*position: relative;*/
    box-shadow: 0px 10px 20px #00000020;
  }

  .KlawiszLogowanie:hover {
    color: black;
    box-shadow: 0px 10px 50px #211880;
    transform: translateY(-5px);
  }
