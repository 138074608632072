* {
  box-sizing: border-box;
}

.StartTabela{
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-columns: 1fr 7fr 2fr;
  grid-template-rows: 1fr 7fr 2fr;
  border: solid rgb(51, 153, 73);
  padding-left: 1%;
  padding-top: 1%;
  padding-right: 4%;
  padding-bottom: 1%;
}

.logowanie{
  /*border: solid;*/
  padding-left: 20%;
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}

.baner{
  
 /* border: solid;*/
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  text-align: center;
}

.Informacje {
  font-size: 25px;
  border: solid;
  border-color: rgb(184, 177, 177);
  padding: 2%;
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
  box-shadow: 10px 14px 80px rgba(34, 35, 58, 0.2);
  border-radius: 15px;

}
  
.PasekGorny{
  width: 100vw;
  height: 10vh;
  
  border: solid;
  border-color: blue;
  float:right;
  padding-top: 5px;
  
  
}


