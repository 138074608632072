.okno_naliczenia {
    width: 550px;
    height: 500px;
    margin: auto auto;
    background: #c6bcbcbb;
    color: black;
    text-align: center;
    box-shadow: 10px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
  }
.wybor_miesiaca
{
  border: solid 1px;
  width: 400px;
  height: 50px;
  display: flex;
  float: left;
  margin-bottom: 3px;
}
.label_wybor_miesiaca
{
  margin-left: 5px;
  margin-right: 15px;
  margin-top: 8px;
}
.select_wybor_miesiaca
{
  width: 150px;
  height: 100px;
  margin-top: 3px;
  margin-bottom: 25px;
}
.button_zamknij
{
  width: 90px;
  height: 25px;
  margin-top: 10px;
  margin-left: 7px;
}
.tabela_naliczenia{
  width: 100%;
  height: 100%;
 
}



.data_od{
  position: relative;
  width: 100px;
  left: 0px;
  top:0px;
}

.data_do{
  position: relative;
  width: 100px;
  left: 0px;
  top:0px;
}

.kwota{
  position: relative;
  width: 100px;
  top:0px;
}

.nazwa_skladnika{
  position: relative;
  width: 200px;
  left:0;
  top:0;
}

  